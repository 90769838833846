import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { log } from '@common/log/log';

@Injectable({
    providedIn: 'root'
})

export class FileServerService {
    constructor(
        private http: HttpClient
    ){}

    GetByFilePath(filepath: string) {
        return this.http.post(environment.api + "/serve_files/by_filepath", {Filepath: filepath}, {responseType: 'blob'}).pipe(a => a)
    }

	// if the file exists in S3 or in the data directory, this should find it
	MaybeGetFileByRelativePath(rpath: string) {
		return this.http.get(this.GetAbsPath(rpath), {
			responseType: 'blob',
		}).pipe(a => a);
	}

	GetAbsPath(rpath: string) {
		let pathIsAlreadyAbsolute = environment.api.indexOf('http') === 0;
		let prefix = pathIsAlreadyAbsolute ? '' : window.location.origin;
		return prefix + environment.api + "/serve_file/by_filepath/" + rpath;
	}

	NameFile(rpath: string) {
		let parts = rpath.split('/');
		let count = parts.length;

		return parts[count-1];
	}
}
