import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { log } from '../log/log';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { AppService } from '../services/app.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
  	public auth: AuthService,
  	private router: Router,
  	private notificationService: NotificationService,
  	private appService: AppService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler){

    // request = request.clone({
    //   setHeaders: {
    //     Authorization: `Bearer ${this.auth.getToken()}`,
    //   }
      // });
	  request = request.clone({
		  withCredentials: true,
	  });

      return next.handle(request).pipe(tap(event => {}, exception => {
		  console.log(exception);
    	if(exception instanceof HttpErrorResponse) {
    		log.Debug("exception: ", exception);
    		switch(exception.status) {
    			case 500:
    				log.Debug("internal server error")
    				if( exception.url.includes("events/current") ) {
    					this.auth.logout();
    					this.notificationService.Error("Unable to find data: Please try logging in again.");
    					this.appService.navigate(['login']);
    				} else {
    					this.notificationService.Error("Internal Server Error: " + exception.error);
    				}
    				break;

    			case 401:
    				this.notificationService.Error("You do not have permission to view some of this content.");
    				break;
    		}
    	}
    }));
  }
}
