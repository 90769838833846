import { Routes } from '@angular/router';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { ContactInfoResolve } from './contact-info/contact-info.resolve';
import { DestinationComponent } from './destination/destination.component';
import { ManifestComponent } from './manifest/manifest.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScheduleResolve } from './schedule/schedule.resolve';
import { ShipmentResolve } from './shipment.resolve';
import { ManifestResolve } from './manifest/manifest.resolve';
import { ManifestErrorsDialogComponent } from './manifest/manifest.component';
import { CurrentManifestInfoResolve } from '../manifest-info/current-manifest-info.resolve';
import { EventResolve } from '../../event';
import { ShippingClosedGuardService } from '../../common/services/shipping-closed-guard.service';

export const SHIPMENT_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'contact-info',
    },
    {
        path: 'contact-info',
      component: ContactInfoComponent,
	  canActivate: [ShippingClosedGuardService],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        resolve: {data: ContactInfoResolve}
    },
    {
        path: 'destination',
        component: DestinationComponent
    },
    {
        path: 'manifest',
        component: ManifestComponent,
        resolve: {
            data: ManifestResolve,
            event: EventResolve,
        }
    },
    {
        path: 'schedule',
        component: ScheduleComponent,
        resolve: {data: ScheduleResolve}
    }
];

export const SHIPMENT_COMPONENTS = [
    ContactInfoComponent,
    DestinationComponent,
    ManifestComponent,
    ScheduleComponent,
    ManifestErrorsDialogComponent,
];

export const SHIPMENT_PROVIDERS = [
    ContactInfoResolve,
    ScheduleResolve,
    ShipmentResolve,
    ManifestResolve,
    CurrentManifestInfoResolve
];

export const SHIPMENT_ENTRY = [
    ManifestErrorsDialogComponent,
]
