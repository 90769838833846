import { Component, OnInit } from '@angular/core';
import { FileManagementService } from '../../common/services/file-management.service';
import { FileServerService } from '../../common/services/file-server.service';
import { log } from '@common/log/log';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';


// export interface Response {
//     Contents?: any[];
// }

@Component({
    selector: 'app-files',
    templateUrl: './files.component.html',
    styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {

    // public resp: Response = {
    //     Contents: []
    // };
	public resp: any = [];
    public bucketPath: string = "https://product-walk-app.s3.amazonaws.com/";

    form: FormGroup

    pool = [];

    constructor(
        private FileManagementService: FileManagementService,
		private fss: FileServerService,
        private _snack: MatSnackBar,
        private FormBuilder: FormBuilder
    ) {}

    ngOnInit() {

        this.form = this.FormBuilder.group({
            'file': new FormControl(),
        })

        this.FileManagementService.listAll().subscribe(r => {
		this.resp = r;
 //           log.Debug("files: ", r['Contents'])
            //this.pool = r['Contents']
        })
    }

    upload($event) {
        let formData = new FormData()
        formData.append('file', $event.target.files[0])
        log.Debug("form value: ", this.form.value)
        log.Debug("formdata: ", formData)
        this.FileManagementService.upload(formData).subscribe( r => {
            log.Debug("resp: ", r)
            this._snack.open('File Uploaded!',null ,{duration: 3000});
            this.FileManagementService.listAll().subscribe( r => {
                this.resp = r;
            })
        })
    }

    copyInputMessage(inputElement){
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
        this._snack.open('Link copied to clipboard!',null ,{duration: 2000});
      }

    delete(key) {
		console.log("key: ", key)
        if (confirm('Are you sure that you want to delete ' + key + '?')) {
            this.FileManagementService.delete(key).subscribe( r => {
                this._snack.open('File Deleted!',null ,{duration: 3000});
                this.FileManagementService.listAll().subscribe( r => {
                    this.resp = r
                })
            })
        }
    }

	urlForFile(key: string): string {
		return this.fss.GetAbsPath(key);
	}

}
