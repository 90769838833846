import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { log } from '../../common/log/log';
import { User } from '../../models/user'
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from './app.service'
// import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { GenericObj } from '../../models/global';
import { LocalStorage } from '../decorators/localstorage';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

  groups = AuthGroups;


  @LocalStorage("token")
  public _token: string
  @LocalStorage("role")
  private _role: string
  @LocalStorage("contractStatus")
  private _contractStatus: string
  @LocalStorage("companyType")
  private _companyType: string
  @LocalStorage("user")
  private _user: GenericObj
  @LocalStorage("expiry")
  private _expiry: Date
  @LocalStorage("ship_only")
  private _shipOnly: boolean


  constructor(
    public http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService
  ) {}

  public isAuthenticated(): boolean {

    return !!this._token;
  }

  public getToken() {
    return this._token;
  }

  // public setToken(token) {
  //   return localStorage.setItem('token', token);
  // }

  public setContractStatus(cs) {
    this._contractStatus = cs
  }

  public getUser() {
    log.Debug("user: ", this._user)
    log.Debug("expiry: ", new Date(this._expiry))
    log.Debug("token: ", this._token);
    const expiry = new Date(this._expiry);
    const today = new Date();
    if (today >= expiry || expiry == null || !this._user || !this._role) {
      log.Debug("user done went and expired");
      this.logout();
      this.appService.navigate(['login']);
      return null;
    }

    return this._user;
  }

  public getUserID() {
    let user = this.getUser();
    if (user) {
      return user.id;
    }
    return null
  }

  public getRole() {
    return this._role;
  }

  public isPrimary() {
    return this.getRole() == 'supplier_primary_contact' || this.getRole() == 'admin';
  }

  public getContractStatus() {
    return this._contractStatus;
  }

  public getShipOnly() {
    return this._shipOnly;
  }

  public getCompanyType() {
    return this._companyType;
  }

  public login(data) {
    return this.http.post(environment.api + "/users/authenticate", data).pipe(
      map(r => {
        if (!r)
          throw new Error('Unable to Authenticate User');
        return r;
      }),
      tap(r => {
        // localStorage.setItem('token', r["Token"]["Token"]);
          this._token = r["Token"]["Token"];
		  let expires = r["Token"]["ExpireTime"];
        r["User"]["password"] = ""
        this._role = this.GetRole(r);
          // const today = new Date();
		  // hey, why not just use the expiration on the token? TJ
          // this._expiry = new Date(today.getTime() + (1000 * 60 * 60 * 24 * 30));
		  // expires is unix timestamp
		  this._expiry = new Date(expires * 1000);
        this._user = r['User'];
        this._contractStatus = r["ContractStatus"];
        this._companyType = r["CompanyType"]
        this._shipOnly = r['ShipOnly']
        // localStorage.setItem("user", JSON.stringify(r["User"]));
        // localStorage.setItem("role", role);
        // localStorage.setItem("contract_status", r["ContractStatus"]);
        // localStorage.setItem("ship_only", r["ShipOnly"]);
        // localStorage.setItem("company_type", r["CompanyType"]);
        // localStorage.setItem("expiry", expiry.toString());
      }));
  }

  GetRole(r: GenericObj) {
    let role = r["Role"].toLowerCase().replace(" ", "_");
    if ("supplier" === role) {
      if (r["User"]["is_primary"]) {
        role += "_primary_contact"
      }
      if (r["ShipOnly"]) {
        role += "_ship_only"
      }
    }
    return role;
  }

  HasPermission(ag: string[]): boolean {
    let role = this.getRole()
    if (ag.indexOf(role) > -1) {
      return true
    }
    return false
  }

  public logout() {
    this._token = null;
    this._user = null;
    this._role = null;
    this._contractStatus = null;
    this._expiry = null;
    // localStorage.removeItem('token');
    // localStorage.removeItem('user');
    // localStorage.removeItem('role');
    // localStorage.removeItem('contract_status');
    // localStorage.removeItem('expiry');
  }
}

export enum AuthRoles {
  USER_MANAGER = "user_manager",
  SUPER_ADMIN = "super_admin",
  ADMIN = 'admin',
  ACCOUNTING = 'accounting',
  SUPPLIER_COORDINATOR = 'supplier_coordinator',
  SUPPLIER_PRIMARY_CONTACT_SHIP_ONLY = 'supplier_primary_contact_ship_only',
  SUPPLIER_PRIMARY_CONTACT = 'supplier_primary_contact',
  SUPPLIER_SHIP_ONLY = 'supplier_ship_only',
  SUPPLIER = 'supplier',
  VENDOR = 'vendor'
}

export class AuthGroups {
  static USER_MANAGER: AuthRoles[] = [AuthRoles.USER_MANAGER, AuthRoles.ADMIN, AuthRoles.SUPER_ADMIN, AuthRoles.SUPPLIER_COORDINATOR];
  static SUPER_ADMIN: AuthRoles[] = [AuthRoles.SUPER_ADMIN];
  static ADMIN: AuthRoles[] = [AuthRoles.ADMIN, ...AuthGroups.SUPER_ADMIN];
  static ACCOUNTING: AuthRoles[] = [AuthRoles.ACCOUNTING, ...AuthGroups.ADMIN];
  static VENDOR: AuthRoles[] = [AuthRoles.VENDOR, ...AuthGroups.ACCOUNTING];
  static SUPPLIER_COORDINATOR: AuthRoles[] = [AuthRoles.SUPPLIER_COORDINATOR, ...AuthGroups.ADMIN];
  static SUPPLIER_PRIMARY_CONTACT_SHIP_ONLY: AuthRoles[] = [
    AuthRoles.SUPPLIER_PRIMARY_CONTACT_SHIP_ONLY,
    ...AuthGroups.SUPPLIER_COORDINATOR
  ];
  static SUPPLIER_PRIMARY_CONTACT: AuthRoles[] = [
    AuthRoles.SUPPLIER_PRIMARY_CONTACT,
    ...AuthGroups.SUPPLIER_COORDINATOR
  ];
  static SUPPLIER_SHIP_ONLY: AuthRoles[] = [AuthRoles.SUPPLIER_SHIP_ONLY, ...AuthGroups.SUPPLIER_PRIMARY_CONTACT_SHIP_ONLY];
  static SUPPLIER: AuthRoles[] = [
    AuthRoles.SUPPLIER,
    ...AuthGroups.SUPPLIER_PRIMARY_CONTACT
  ];
}
