import { Component,OnInit, Input } from '@angular/core';
import {FileServerService } from '@common/services/file-server.service';

@Component({
	selector: 'pgl-file-link',
	template: '<a [href]="downloadURL" target="_blank">{{fileTitle}}</a>',
})
export class FileLinkComponent implements OnInit {
	@Input() filepath: string;
	@Input() fileTitle: string;

	public downloadURL: string;

	constructor(
		public fss: FileServerService,
	){}

	public ngOnInit() {
		if (!this.fileTitle) {
			this.fileTitle = this.fss.NameFile(this.filepath);
		}

		this.downloadURL = this.fss.GetAbsPath(this.filepath);
	}

	// public fetch() {
	// 	this.fss.MaybeGetFileByRelativePath(this.filepath)
	// 		.subscribe(blob => {
	// 			const a = document.createElement('a');
	// 			a.setAttribute('download', this.nameFile())
	// 			a.setAttribute("href", window.URL.createObjectURL(blob));
	// 			a.click();
	// 		})
	// }

	// private nameFile(): string {
	// 	let parts = this.filepath.split('/');
	// 	let count = parts.length;
	// 	return parts[count-1];
	// }
}
