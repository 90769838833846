import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LabelDialogComponent } from '../shipments/label-dialog/label-dialog.component';
import { ManifestDialogComponent } from '../shipments/manifest-dialog/manifest-dialog.component';
import { AuthService } from '@common/services/auth.service';
import { log } from '../../common/log/log';

@Component({
    selector: 'manifest-info',
    templateUrl: './manifest-info.component.html',
    styleUrls: ['./manifest-info.component.css']
})

export class ManifestInfoComponent implements OnInit {

    manifest;
    manifestPackages;
    manifestLineItems;
    company;
    department;
    shippingDestination;
    packageColumns: string[];
    lineItemColumns: string[];
    // lineItemColumnsWithoutDispositionColumns: string[];
    event;
    displayedColumns: string[];

    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog,
        public as: AuthService,
    ){
        this.packageColumns = [
			'quantity',
            'type',
            'width',
            'height',
            'depth',
            'weight',
        ];
        this.lineItemColumns = [
            'item',
            'quantity',
            'description',
            'display_type',
            'sku',
        ];
    }

    ngOnInit() {
        this.route.data.subscribe(data => {
            log.Debug("data: ", data);
            this.manifest = data.manifestInfo.manifest;
            this.company = data.manifestInfo.company;
            this.department = data.manifestInfo.department;
            this.shippingDestination = data.manifestInfo.shippingDestination;
            this.manifestPackages = data.manifestInfo.manifestPackages;
            this.manifestLineItems = data.manifestInfo.manifestLineItems;
            this.event = data.event;
        });
        log.Debug("manifest: ", this.manifest);
      log.Debug("manifestPackages: ", this.manifestPackages);
	  log.Debug("the event: ", this.event);
        this.displayedColumns = this.lineItemColumns
        if( this.event.has_disposition_columns ) {
            this.displayedColumns.push('discard', 'return', 'return_method');
            if( this.event.has_donate_columns ) {
            	this.displayedColumns.push('donate', 'donate_value');
            }
            this.displayedColumns.push('return_address');
        }
    }

    openPrintLabel(){
		this.dialog.open(LabelDialogComponent, {
			width: '900px',
			height: '800px',
			data: {
				dataKey: this.manifest,
                event: this.event,
			}
		});
	}

	openManifestLabel(){
		this.dialog.open(ManifestDialogComponent, {
			width: '900px',
			height: '800px',
			data: {
				dataKey: this.manifest,
                event: this.event,
			}
		});
    }

    currentRole(){
		return this.as.getRole().toLowerCase();
	}

}
