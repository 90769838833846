import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FooterComponent } from './footer/footer.component';
import { SignupComponent } from './signup/signup.component';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AngularFileUploaderModule } from "angular-file-uploader";

import { AppRoutingModule, ROUTE_COMPONENTS } from './app-routing.module';
import { FullMaterialModule } from './material-module';
import { MainComponent } from './main/main.component';
import { AppComponent } from './app.component';
import { SERVICES } from './common/services';
import { SHIPMENT_PROVIDERS } from './shipping/shipment';
import { SUPPLIER_COMPONENTS, SUPPLIER_PROVIDERS } from './admin/suppliers/index';
import { USER_COMPONENTS } from './admin/users/index';
import { COMPANY_COMPONENTS } from './admin/companies/index';
import { ANALYTICS_COMPONENTS, ANALYTICS_PROVIDERS } from './admin/analytics/index';
import { DEPARTMENT_COMPONENTS } from './admin/departments/index';
import { INVOICE_TEMPLATE_COMPONENTS } from './admin/invoice-templates/index';
import { GROUP_COMPONENTS } from './admin/groups/index';
import { SHIPPING_DESTINATION_COMPONENTS } from './admin/shipping-destinations/index';
import { MANAGE_REGISTRATION_COMPONENTS } from './admin/manage-registration/index';
import { MENU_COMPONENTS } from './admin/menus/index';
import { PipesModule } from './common/pipes/pipes.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InvitesComponent } from './admin/invites/invites.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { InvoicesComponent } from './admin/invoices/invoices.component';
import { FileDropComponent, FileLinkComponent } from './common/components';
import { PglFileDropDirective } from './common/components/file-drop/file-drop.directive';
import { ContentService } from './common/services/content.service';
import { NgPipesModule } from 'ngx-pipes';
import { ActivateComponent } from './activate/activate.component';
import { ShipmentsComponent } from './shipping/shipments/shipments.component';
import { InlineTextEditComponent } from './admin/common/inline-edit/inline-text-edit/inline-text-edit.component';
import { InviteDialogComponent } from './admin/invites/invite-dialog/invite-dialog.component';
import { ScheduleComponent } from './shipping/shipment/schedule/schedule.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { OutboundDispositionSearchComponent } from './shipping/outbound-disposition-search/outbound-disposition-search.component';
import { TokenInterceptor } from './common/interceptors/auth.interceptor';
import { ArchiveInterceptor } from './common/interceptors/archive.interceptor';
import { ActivateResolve } from './activate/activate.resolve';
import { LockDatesDialogComponent } from './admin/shipping-destinations/lock-dates-dialog/lock-dates-dialog.component';
import { MenusComponent } from './admin/menus/menus.component';
import { AddMenuComponent } from './admin/menus/add-menu/add-menu.component';
import { EditMenuComponent } from './admin/menus/edit-menu/edit-menu.component';
import { ResetPasswordResolve } from './admin/users/reset-password/reset-password.resolve';
import { AddPageComponent } from './admin/pages/add-page/add-page.component';
import { EditPageComponent } from './admin/pages/edit-page/edit-page.component';
import { ContractComponent } from './contract/contract.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppService } from './common/services/app.service';
import { FILE_COMPONENTS } from './admin/file-managment';


import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { EditorModule } from '@tinymce/tinymce-angular';
import { EditPageContentComponent } from './admin/pages/edit-page-content/edit-page-content.component';
import { PageComponent } from './page/page.component';
import { PaymentComponent } from './invoices/payment/payment.component';
import { PaymentsComponent } from './admin/payments/payments.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { ReportsComponent } from './admin/reports/reports.component';
import { InvoicesDialogComponent } from './admin/companies/edit-company/invoices-dialog/invoices-dialog.component';
import { PayInvoiceComponent } from './invoices/pay-invoice/pay-invoice.component';
import { ImportsComponent } from './admin/imports/imports.component';
import { AttendeeDialogComponent } from './admin/imports/attendee-dialog/attendee-dialog.component';
import { EditRegistrationComponent } from './registration/edit-registration/edit-registration.component';
import { LabelDialogComponent } from './shipping/shipments/label-dialog/label-dialog.component';
import { ManifestErrorsDialogComponent } from './shipping/shipment/manifest/manifest.component';
// import { TextMaskModule } from 'angular2-text-mask';

import { SHIPMENT_ENTRY } from './shipping/shipment/index';
import { OutboundDispositionComponent } from './admin/outbound-disposition/outbound-disposition.component';
import { ManifestDialogComponent } from './shipping/shipments/manifest-dialog/manifest-dialog.component';
import { DatePipe } from '@angular/common';

import { LinkWatcher } from './common/directives/link-watcher.directive';
import { SETTINGS_PROVIDERS } from './admin/settings';

export function tokenGetter() {
    return localStorage.getItem('token');
}

import { UnderConstructionComponent } from './admin/under-construction/under-construction.component';
import { EventResolve } from './event';
import { ROLES_PROVIDERS } from './role';
import { COMPANY_TYPES_PROVIDERS } from './company_type';
import { RegistrationInterceptDialog } from './registration/registration-intercept-dialog/registration-intercept.dialog';

import { AuthService } from './common/services/auth.service';


@NgModule({
    declarations: [
        AppComponent,
        ...ROUTE_COMPONENTS,
        ...USER_COMPONENTS,
        ...MANAGE_REGISTRATION_COMPONENTS,
        ...COMPANY_COMPONENTS,
        ...ANALYTICS_COMPONENTS,
        ...DEPARTMENT_COMPONENTS,
        ...INVOICE_TEMPLATE_COMPONENTS,
        ...GROUP_COMPONENTS,
        ...SHIPPING_DESTINATION_COMPONENTS,
        ...SUPPLIER_COMPONENTS,
        ...MENU_COMPONENTS,
        ...FILE_COMPONENTS,
        InvitesComponent,
        SettingsComponent,
        InvoicesComponent,
        FileDropComponent,
		FileLinkComponent,
        ActivateComponent,
        ShipmentsComponent,
        InlineTextEditComponent,
        InviteDialogComponent,
        ScheduleComponent,
        LoginComponent,
        FooterComponent,
        SignupComponent,
        OutboundDispositionSearchComponent,
        MainComponent,
        LockDatesDialogComponent,
        MenusComponent,
        AddMenuComponent,
        EditMenuComponent,
        AddPageComponent,
        EditPageComponent,
        ContractComponent,
        EditPageContentComponent,
        PageComponent,
        PaymentComponent,
        PaymentsComponent,
        ReportsComponent,
        InvoicesDialogComponent,
        PayInvoiceComponent,
        ImportsComponent,
        AttendeeDialogComponent,
        EditRegistrationComponent,
        LabelDialogComponent,
        OutboundDispositionComponent,
        PglFileDropDirective,
        ManifestDialogComponent,
        UnderConstructionComponent,
        LinkWatcher,
        RegistrationInterceptDialog,
        InviteDialogComponent,
        LockDatesDialogComponent,
        EditPageContentComponent,
        InvoicesDialogComponent,
        AttendeeDialogComponent,
        LabelDialogComponent,
        ManifestDialogComponent,
        ManifestErrorsDialogComponent,
        RegistrationInterceptDialog
    ],
    imports: [
        BrowserModule,
        EditorModule,
        AppRoutingModule,
        FullMaterialModule,
        NgxMatSelectSearchModule,
        // TextMaskModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        PipesModule,
        NgPipesModule,
        // AngularFileUploaderModule,
        HttpClientModule,
        // NATODO watch, from update
        NgxMaskDirective,
        NgxMaskPipe,
        DragDropModule,
        JwtModule,
        // NATODO watch, from update
        // JwtModule.forRoot({
        //     config: {
        //         tokenGetter: tokenGetter,
        //         whitelistedDomains: ['localhost'],
        //         blacklistedRoutes: []
        //     }
        // })
    ],
    providers: [
        ...SERVICES,
        ...SHIPMENT_PROVIDERS,
        ...SUPPLIER_PROVIDERS,
        ...ANALYTICS_PROVIDERS,
        ...ROLES_PROVIDERS,
        ...COMPANY_TYPES_PROVIDERS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ArchiveInterceptor,
            multi: true
        },
        ActivateResolve,
        ResetPasswordResolve,
        DatePipe,
        ...SETTINGS_PROVIDERS,
        EventResolve,
        provideNgxMask()
    ],
    bootstrap: [MainComponent]
})
export class AppModule { }
