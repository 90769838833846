import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventService } from './event.service';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { log } from '../log/log';

@Injectable({
  providedIn: 'root'
})
export class ShippingClosedGuardService implements CanActivate {

  constructor(
    public EventService: EventService,
	private snackBar: MatSnackBar,
  ) {}

  canActivate(): Observable<boolean> {
    return this.EventService.GetEvent().pipe(
      take(1),
      map(event => {
        if(event['is_shipping_closed']) {
		  // do snackbar message
		  this.snackBar.open('Shipping is Closed', null, {duration: 5000});
          return false
        }
        return true
      }),
      catchError(err => of(false)),
      shareReplay(1)
    )
  }
}
